import React from 'react';
import { string, oneOfType, object } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

function WeddingDetail({ title, titleProps, subTitle, date, time, dresscode, info, info2 }) {
  return (
    <Box marginBottom="24px" color="secondaryColorText">
      <Text margin="8px 0" textAlign="center" fontSize="2xl" fontFamily="fantasy" {...titleProps}>
        {title}
      </Text>
      <Text margin="8px 0" fontSize="xs" textAlign="center" textTransform="uppercase">
        {subTitle}
      </Text>
      <Text fontSize="sm" textAlign="center" textTransform="uppercase" fontWeight="bold">
        {date}
      </Text>
      <Text fontSize="sm" textAlign="center" textTransform="uppercase" fontWeight="bold">
        {time}
      </Text>
      {dresscode && (
        <Text fontSize="sm" textAlign="center" textTransform="uppercase" fontWeight="bold">
          {`Dresscode: ${dresscode}`}
        </Text>
      )}
      {info && (
        <Text fontSize="sm" textAlign="center" textTransform="uppercase" fontWeight="bold">
          {`*) ${info}`}
        </Text>
      )}
      {info2 && (
        <Text fontSize="sm" textAlign="center" textTransform="uppercase" fontWeight="bold">
          {`${info2}`}
        </Text>
      )}
    </Box>
  );
}

WeddingDetail.propTypes = {
  date: string.isRequired,
  dresscode: string,
  info: string,
  info2: string,
  subTitle: oneOfType([string, object]),
  time: string.isRequired,
  title: string.isRequired,
  titleProps: object,
};

WeddingDetail.defaultProps = {
  dresscode: '',
  titleProps: {},
};

export default React.memo(WeddingDetail);
