import React from 'react';
import { Box, Heading, Center } from '@chakra-ui/react';

import useInvitation from '@hooks/useInvitation';
import CountDown from '@/components/Common/CountDown/V3';
import AddToCalendar from '@/components/Common/AddToCalendar';
import WithAnimation from '@/components/Common/WithAnimation';

import {DEFAULT_LANGUAGE as lang} from '@/constants/feature-flags'
import { BG_COUNTING } from '@/constants/assets';
import { styles } from './styles';

import txtWording from './locales';

function CountingDown() {
  const invitation = useInvitation();

  return (
    <Box zIndex="3" position="relative">
      <Box css={styles} height="100%">
        <Box
          width="100%"
          bgImage={`url(${BG_COUNTING})`}
          minHeight="90vh"
          bgSize="cover"
          bgPosition="center"
        />
      </Box>
      <Box zIndex="2" transform="translateY(-200px)" position="relative" marginBottom="-200px">
        <WithAnimation>
          <Heading
            fontFamily="cursive"
            textAlign="center"
            fontWeight="normal"
            fontSize="5xl"
            color="white"
            marginBottom="-12px"
          >
            Our Vows
          </Heading>
        </WithAnimation>
        <CountDown lang={lang} />
        {invitation && (
          <WithAnimation>
            <Center>
              <AddToCalendar
                text={txtWording.reminder[lang]}
                size="xs"
                fontWeight="normal"
                color="secondaryColorText"
                bgColor="bgSecondary"
                className="animation-heart"
                letterSpacing="2px"
              />
            </Center>
          </WithAnimation>
        )}
      </Box>
      <Box height="200px" marginTop={invitation ?"-400px" :"-370px"} />
    </Box>
  );
}

export default CountingDown;