import React from 'react';
import { Box, Center, Text, Link, Divider, IconButton } from '@chakra-ui/react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

import Image from '@/components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';

import { THE_BRIDE, SOUND_BY, SOUND_URL, URL_WA_INVITATO, URL_IG_INVITATO } from '@/constants';
import { BG_FOOTER, LOGO_INVITATO, IMG_LOGO } from '@/constants/assets';
import {
  DEFAULT_LANGUAGE as lang,
  ENABLE_COLLABORATION,
  SELECTED_VENDOR_COLLABORATION,
} from '@/constants/feature-flags';

import txtWording from './locales';

function FooterSection() {
  return (
    <Box bgImage={`url(${BG_FOOTER})`} bgSize="cover" minHeight="100vh" bgPos="bottom center">
      {/* Title and Desc Section */}
      <Box
        textAlign="center"
        color="secondaryColorText"
        fontFamily="serif"
        padding="0 24px"
        bottom="4rem"
        position="absolute"
        fontWeight="400"
        fontSize="10px"
        width="100%"
        maxW="500px"
      >
        {/* Logo Section */}
        <WithAnimation>
          <Text
            textTransform="uppercase"
            letterSpacing="3px"
            fontFamily="serif"
            fontSize="lg"
            color="white"
          >
            {txtWording.thanks[lang]}<br />
            #MARingoodHENS
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Center>
            <Image src={IMG_LOGO} maxWidth="150px" marginTop="-4px" />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Center>
            <Divider
              opacity="1"
              height="1px"
              bgColor="white"
              border="none"
              maxWidth="120px"
              marginBottom="24px"
            />
          </Center>
        </WithAnimation>
        {/* INVITATO SECTION */}
        <Box>
          <Center>
            <Link href={URL_IG_INVITATO} target="_blank">
              <Image src={LOGO_INVITATO} maxWidth="120px" />
            </Link>
          </Center>
          <Text color="white" textAlign="center" fontSize="xs">
            Created with Love by Invitato
          </Text>
          {/* Icon IG & WA Not Used */}
          {false && (
            <WithAnimation>
              <Center marginBottom="16px">
                <Link href={URL_IG_INVITATO} target="_blank">
                  <IconButton
                    isRound
                    size="sm"
                    variant="outline"
                    background="#9f6b44"
                    border="none"
                    icon={<FaInstagram color="white" size="20px" />}
                    marginRight="4px"
                  />
                </Link>
                <Link href={URL_WA_INVITATO} target="_blank">
                  <IconButton
                    isRound
                    size="sm"
                    variant="outline"
                    icon={<FaWhatsapp color="white" size="20px" />}
                    marginLeft="4px"
                    background="#9f6b44"
                    border="none"
                  />
                </Link>
              </Center>
            </WithAnimation>
          )}
          {/* Collaboration Partner */}
          {ENABLE_COLLABORATION && (
            <Box marginTop="14px">
              <Center>
                <Divider
                  marginBottom="16px"
                  maxWidth="120px"
                  opacity="1"
                  height="1px"
                  bgColor="white"
                  border="none"
                />
              </Center>
              <Center>
                <Link
                  href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`}
                  target="_blank"
                >
                  <Image
                    src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                    {...SELECTED_VENDOR_COLLABORATION.imgProps}
                  />
                </Link>
              </Center>
              <Center>
                <Text color="white" align="center" fontSize="xs">
                  In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                </Text>
              </Center>
            </Box>
          )}
          {/* Copy Right */}
          <Text
            textAlign="center"
            color="white"
            fontSize="xs"
            marginTop={ENABLE_COLLABORATION ? '12px' : '12px'}
          >
            {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
          </Text>
          <Link textAlign="center" href={SOUND_URL} color="white" fontSize="xs" isExternal>
            {`Song by ${SOUND_BY}`}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);
