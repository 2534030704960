import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear Mr/Mrs/Ms,'),
  friend: get('Keluarga & Teman-teman', 'Family & Friends'),
  announcement: get(
    `Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Tanpa mengurangi rasa hormat, mengingat masih dalam masa pandemi, kami mohon maaf tidak bisa mengundang Bapak/Ibu/Saudara/i secara langsung dalam acara pernikahan kami. Tetapi kami tetap memohon doa restu untuk keberlangsungan acara pernikahan kami.`,
    `We are pleased to announce our upcoming wedding to the family and friends. Our apologize to our loved ones who we wished we could have invited. Considering our venue capacity, we are keeping our guest lists limited to immediate family members only, but we really appreciate your well wishes. We’d love to catch up with you after the wedding`,
  ),
  ayat: get(
    `Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.`,
    `A great marriage is not when the perfect couple comes together. It is when an imperfect couple learns to enjoy their differences.`,
  ),
  ayatName: get('(QS. Ar-Rum: 21)', ''),
};