import React, { useState } from 'react';
import { Box, Center, Button, useDisclosure, useDisclosure as useDisclo } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import ImagesViewer from '@/components/Common/ImagesViewer/Lazy';
import Image from '@/components/Common/LazyImage';
import FilterSection from '@/components/Section/Filter';

import { DEFAULT_LANGUAGE as lang, ENABLE_WEDDING_FILTER } from '@/constants/feature-flags';
import { IMG_SLIDE_1, IMG_SLIDE_2 } from '@/constants/assets';

import Content from './Content';
import txtWording from './locales';

function PhotoSection() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const disclosure = useDisclo();

  const openImageViewer = (index) => {
    setActiveIndex(index);
    disclosure.onOpen();
  };

  const openGallery = () => {
    onOpen();
  };

  return (
    <Box bgColor="bgPrimary" paddingBottom="32px">
      {/* Cover Section */}
      <Box>
        <WithAnimation>
          <Center>
            <Image src={IMG_SLIDE_1} maxW="100%" onClick={() => openImageViewer(0)} />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Center>
            <Image src={IMG_SLIDE_2} maxW="100%" onClick={() => openImageViewer(1)} />
          </Center>
        </WithAnimation>
      </Box>
      {/* Button Section */}
      <WithAnimation>
        <Center>
          <Button
            size="sm"
            fontWeight="light"
            bgColor="bgSecondary"
            marginTop="24px"
            color="secondaryColorText"
            textTransform="uppercase"
            letterSpacing="2px"
            onClick={() => openGallery()}
          >
            {txtWording.buttonGallery[lang]}
          </Button>
        </Center>
      </WithAnimation>
      {/* Content gallery */}
      <Content isOpen={isOpen} onClose={onClose} />
      {disclosure.isOpen && (
        <ImagesViewer
          images={[IMG_SLIDE_1, IMG_SLIDE_2]}
          onClose={disclosure.onClose}
          firstIndex={activeIndex}
        />
      )}
      {ENABLE_WEDDING_FILTER && <FilterSection />}
    </Box>
  );
}

export default React.memo(PhotoSection);
