import React, { Fragment, useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';

import useMutation from '@/hooks/useMutation';
import useGuestName from '@/hooks/useGuestName';
import usePartner from '@/hooks/usePartner';

import { BG_RSVP } from '@/constants/assets';
import { API_HOSTNAME, API_RSVP_TABLE } from '@/constants';
import { ENABLE_GUEST_PERSONALIZATION } from '@/constants/feature-flags';

import { txtForm, txtTitle } from './locales';
import useShiftTime from '@hooks/useShiftTime';

const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};

const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'sm',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgSecondary',
  color: 'secondaryColorText',
  fontSize: 'md',
  borderRadius: '0',
  fontFamily: 'serif',
  _placeholder: { color: 'secondaryColorText' },
  _focus: { borderColor: 'secondaryColorText' },
};

const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'bgSecondary',
  color: 'secondaryColorText',
  fontFamily: 'serif',
  _focus: { borderColor: 'secondaryColorText' },
};

const FORM_LABEL_PROPS = {
  ontSize: 'sm',
  color: 'mainColorText',
  margin: '8px 0',
  fontFamily: 'serif',
};

const enc = (s) => encodeURIComponent(s);

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const guestName = useGuestName();
  const partner = usePartner();
  const finalName = guestName.toLowerCase() === 'guest' ? '' : guestName;
  const time = useShiftTime();
  const [onSentConfirmation, loading] = useMutation(API_HOSTNAME, API_RSVP_TABLE, 'insert');

  const [name, setName] = useState(() => finalName);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('-');
  const [attended, setAttended] = useState('Pemberkatan');
  const [bringPartner, setBringPartner] = useState(1);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  
  /**
   * function to set state
   * @param {object} e - html event value
   * @param {function} setState
   * @param {string} typeState
   * @returns
   */
  const onSetState = (e, setState, typeState) => {
    const value = e.target.value;
    setState(value);

    if (typeState === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrorType({ ...errorType, email: txtForm.invalidEmail[lang] });
        return;
      }
    }

    if (typeState === 'phone') {
      if (!/\d+/.test(value) || value.length > 13) {
        setErrorType({ ...errorType, phone: txtForm.invalidPhone[lang] });
        return;
      }
    }

    setErrorType(ERROR_TYPE);
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    if (errorType.name || errorType.phone || errorType.address || errorType.email) return;

    if (!name || !phone || !address || !email) {
      setErrorType({
        name: !name ? txtForm.required[lang] : '',
        phone: !phone ? txtForm.required[lang] : '',
        address: !address ? txtForm.required[lang] : '',
        email: !email ? txtForm.required[lang] : '',
      });

      return;
    }

    const query =
      `nama=${enc(name)}` +
      `&hp=${phone}` +
      `&email=${email}` +
      `&hadir=${enc(attended)}` +
      `&jumlah_tamu=${attended === TYPE.NO ? '' : bringPartner}` +
      `&alamat=${enc(address)}`;

    const result = await onSentConfirmation(query);

    if (result.success) {
      toast({
        title: txtForm.success[lang],
        description: txtForm.msgSuccess[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      setPhone('');
      setEmail('');
      setAddress('');
    } else {
      toast({
        title: 'Oops!',
        description: txtForm.msgError[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box padding="0" bgColor="bgPrimary" {...rest}>
      <Image src={BG_RSVP} maxWidth="100%" />
      <Box bgColor="bgPrimary" borderRadius="2xl" borderTopRadius="0" boxShadow="xl">
        <Box padding="8px">
          <WithAnimation>
            <Box padding="24px" width="100%">
              {/* Title & Desc Section */}
              <Box textAlign="center" color="mainColorText">
                <Text fontSize="3xl" marginTop="16px">
                  RSVP
                </Text>
                <Text fontSize="md" fontFamily="serif" margin="16px 0 24px 0">
                  {txtTitle[lang]}
                </Text>
              </Box>

              {/* Form Sections - Name */}
              <FormControl margin="8px 0" isInvalid={errorType.name}>
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.name[lang]}:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={name}
                  onChange={(e) => onSetState(e, setName)}
                />
                <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
              </FormControl>

              {/* Form Sections - Phone Number */}
              <FormControl margin="16px 0" isInvalid={errorType.phone}>
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.hp[lang]}:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={phone}
                  onChange={(e) => onSetState(e, setPhone, 'phone')}
                />
                <FormErrorMessage marginTop="4px">{errorType.phone}</FormErrorMessage>
              </FormControl>

              {/* Form Sections - Email */}
              <FormControl margin="16px 0" isInvalid={errorType.email}>
                <FormLabel {...FORM_LABEL_PROPS}>Email:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={email}
                  onChange={(e) => onSetState(e, setEmail, 'email')}
                />
                <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
              </FormControl>

              {/* Form Sections - Address */}
              {false && (
                <FormControl margin="16px 0" isInvalid={errorType.address}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.address[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    height="40px"
                    placeholder="..."
                    value={address}
                    onChange={(e) => onSetState(e, setAddress)}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Attendance */}
              <FormControl margin="8px 0">
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYoutAttend[lang]}</FormLabel>
                <Select
                  {...SELECT_COMMON_PROPS}
                  value={attended}
                  onChange={(e) => onSetState(e, setAttended)}
                >
                  {time == 'akad' &&
                    <option value="Pemberkatan" color="white" style={{ color: 'black' }}>
                      Yes, I will gladly attend holy matrimony only  
                    </option>
                  }
                  {time == 'after' &&
                  <>
                    <option value="Pemberkatan" color="white" style={{ color: 'black' }}>
                      Yes, I will gladly attend holy matrimony only  
                    </option>
                    <option value="After Party" color="white" style={{ color: 'black' }}>
                      Yes, I will gladly attend after party only  
                    </option>
                    <option value="Pemberkatan + After Party" color="white" style={{ color: 'black' }}>
                      Yes, I will gladly attend both 
                    </option>
                  </>
                  }
                  {time == 'resepsi' &&
                  <>
                    <option value="Pemberkatan" color="white" style={{ color: 'black' }}>
                      Yes, I will gladly attend holy matrimony only  
                    </option>                    
                    <option value="Resepsi + After Party" color="white" style={{ color: 'black' }}>
                      Yes, I will gladly attend reception and after party only
                    </option>
                    <option value="All Events" color="white" style={{ color: 'black' }}>
                      Yes, I will gladly attend all events
                    </option>
                  </>
                  }
                  <option value={TYPE.NO} style={{ color: 'black' }}>
                    {txtForm.noAttend[lang]}
                  </option>
                </Select>
              </FormControl>

              {/* Form Sections - Partner */}
              {(attended == 'Pemberkatan' || attended == 'After Party' || attended == 'Resepsi' || attended == 'Pemberkatan + After Party' || attended == 'Resepsi + After Party' || attended == 'All Events') && (
                <>
                  <FormControl>
                    <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYouBringPartner[lang]}</FormLabel>
                    <Select
                      {...SELECT_COMMON_PROPS}
                      value={bringPartner}
                      onChange={(e) => onSetState(e, setBringPartner)}
                    >
                      {ENABLE_GUEST_PERSONALIZATION ? (
                        <Fragment>
                          {(partner > 0 || partner === 0) && <option value={1}>1</option>}
                          {partner > 1 && <option value={2}>2</option>}
                          {partner > 2 && <option value={3}>3</option>}
                          {partner > 3 && <option value={4}>4</option>}
                          {partner > 4 && <option value={5}>5</option>}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                        </Fragment>
                      )}
                    </Select>
                    {bringPartner === TYPE.YES && false && (
                      <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                        *) Berdasarkan kondisi saat ini, satu tamu hanya boleh membawa 1 orang
                        partner atau pasangan dalam satu undangan. Terima kasih atas pengertiannya.
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}
              <Center>
                <Button
                  color="secondaryColorText"
                  fontWeight="light"
                  isLoading={loading}
                  marginTop="24px"
                  size="xs"
                  type="button"
                  bgColor="bgSecondary"
                  textTransform="uppercase"
                  letterSpacing="2px"
                  onClick={onSubmitForm}
                >
                  {txtForm.submit[lang]}
                </Button>
              </Center>
            </Box>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
