import React from 'react';
import { Box, Center, Text, Button } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';

import useInvitation from '@/hooks/useInvitation';
import txtWording from './locales';

import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { BG_FILTER } from '@/constants/assets';

/**
 * function to render Filter
 * @returns {JSX.Element}
 */
function FilterSection() {
  const isInvitation = useInvitation();
  
  const handleClickFilterIG = () => {
    window.open('https://www.instagram.com/ar/253911151016046/', '_blank');
  };

  return (
    <Box>
      {isInvitation && (
        <Box>
          <WithAnimation>
            <Text fontSize="2xl" textAlign="center" color="mainColorText" margin="60px 0 12px" fontWeight="normal">
              INSTAGRAM <br />WEDDING FILTER
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Text align="center" color="mainColorText" marginBottom="16px" padding="0 24px 0 24px">
              <span dangerouslySetInnerHTML={{ __html: txtWording.subTitle[lang] }} />
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Box margin="24px 0 24px">
              <Center>
                <Image src={BG_FILTER} maxW="80%" borderRadius="16px" boxShadow="md" />
              </Center>
            </Box>
          </WithAnimation>
          <WithAnimation>
            <Center marginBottom="24px">
              <Button
                leftIcon={<FaInstagram />}
                fontWeight="light"
                size="sm"
                bgColor="bgSecondary"
                color="secondaryColorText"
                textTransform="uppercase"
                letterSpacing="1px"
                onClick={handleClickFilterIG}
              >
                {txtWording.textButton[lang]}
              </Button>
            </Center>
          </WithAnimation>
        </Box>
      )}
    </Box>
  );
}

export default React.memo(FilterSection);