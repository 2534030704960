import React from 'react';
import { string, func } from 'prop-types';
import { Box, Text, Link, Image } from '@chakra-ui/react';

import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import WithAnimation from '@/components/Common/WithAnimation';
import txtWording from './locales';

function DetailCard({ fullName, imgUrl, instagramId, description, onClickReadMore }) {
  return (
    <Box color="mainColorText">
      <WithAnimation>
        <Image src={imgUrl} alt="" width="100%" maxHeight="350px" objectFit="cover" objectPosition="top" />
      </WithAnimation>
      <WithAnimation>
        <Text fontFamily="cursive" fontSize="2xl" marginTop="16px">
          {fullName}
        </Text>
      </WithAnimation>
      {instagramId && (
        <WithAnimation>
          <Link
            href={`https://instagram.com/${instagramId}`}
            target="_blank"
            textDecoration="underline
          "
          >
            {'@' + instagramId}
          </Link>
        </WithAnimation>
      )}
      <WithAnimation>
        <Text marginTop="16px" dangerouslySetInnerHTML={{ __html: description }} />
      </WithAnimation>
      {typeof onClickReadMore !== 'undefined' && (
        <Text
          marginTop="8px"
          textAlign="end"
          fontStyle="italic"
          textDecoration="underline"
          cursor="pointer"
          onClick={onClickReadMore}
        >
          {txtWording.readMore[lang]}
        </Text>
      )}
    </Box>
  );
}

DetailCard.propTypes = {
  fullName: string.isRequired,
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  description: string.isRequired,
  onClickReadMore: func.isRequired,
};

export default React.memo(DetailCard);
